import {
    CreateParams,
    DataProvider,
    DeleteManyParams,
    DeleteParams,
    fetchUtils,
    GetListParams,
    UpdateManyParams,
    UpdateParams
} from "react-admin";
import {
    Admin, AdminPermission, AdminRole,
    BlogCtrbItem,
    BlogItem,
    BlogTag,
    MCampaign,
    MCampaignLog,
    PageResponse,
    RESTFullResponse
} from "../types";

const httpClient = fetchUtils.fetchJson;

const removeObjectProperty = function (obj: Record<string, unknown>, filter: Record<string, unknown>, keyToRemove: string): Record<string, unknown> {
    const { [keyToRemove]: _, ...rest } = obj;
    return rest;
}

const formatDate = (dateStr: string) => {
    return dateStr.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
}

const customDataProvider:DataProvider = {
    //@ts-ignore
    getList: (resource: string, params: GetListParams) => {
        switch (resource) {
            case 'blogctrbitems':
                let blogCtrbItemsPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let blogCtrbItemsFilter  = params.filter?.status !== -100 ? {...params.filter} : removeObjectProperty(params.filter, params.filter as Record<string, unknown>, 'status');
                let blogCtrbItemsParam = { ...blogCtrbItemsFilter, ...blogCtrbItemsPage };
                let blogCtrbItemsUrlSearch = new URLSearchParams(blogCtrbItemsParam);
                let blogCtrbItemsUrlParam =`/api/${resource}?${blogCtrbItemsUrlSearch.toString()}`;
                return httpClient(blogCtrbItemsUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<BlogCtrbItem>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.itemId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
           case 'blogitems':
                let blogItemsPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let blogItemsFilter  = params.filter?.onList !== -1 ? {...params.filter} : removeObjectProperty(params.filter, params.filter as Record<string, unknown>, 'onList');
                let blogItemsParam = { ...blogItemsFilter, ...blogItemsPage };
                let blogItemsUrlSearch = new URLSearchParams(blogItemsParam);
                let blogItemsUrlParam =`/api/${resource}?${blogItemsUrlSearch.toString()}`;
                return httpClient(blogItemsUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<BlogItem>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.itemId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'blogtags':
                let blogTagsPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let blogTagsFilter = params.filter.type !== -1  ? {...params.filter} : removeObjectProperty(params.filter, params.filter as Record<string, unknown>, 'type');
                let blogTagsParam = { ...blogTagsFilter, ...blogTagsPage };
                let blogTagsUrlSearch = new URLSearchParams(blogTagsParam);
                let blogTagsUrlParam = `/api/${resource}?${blogTagsUrlSearch.toString()}`;
                return httpClient(blogTagsUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<BlogTag>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.tagId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                let mcampaignPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let mcampaignFilter = params.filter && {...params.filter};
                let mcampaignParam = { ...mcampaignFilter, ...mcampaignPage };
                let mcampaignUrlSearch = new URLSearchParams(mcampaignParam);
                let mcampaignUrlParam = `/api/${resource}?${mcampaignUrlSearch.toString()}`;
                return httpClient(mcampaignUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<MCampaign>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.campaignId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                let mcampaignLogPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let mcampaignLogFilter = params.filter && {...params.filter};
                let mcampaignLogParam = { ...mcampaignLogFilter, ...mcampaignLogPage };
                let mcampaignLogUrlSearch = new URLSearchParams(mcampaignLogParam);
                let mcampaignLogUrlParam = `/api/${resource}?${mcampaignLogUrlSearch.toString()}`;
                return httpClient(mcampaignLogUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<MCampaignLog>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.logId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'admin':
                let adminListPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let adminListFilter = {...params.filter};
                let adminListParam = { ...adminListFilter, ...adminListPage };
                let adminListUrlSearch = new URLSearchParams(adminListParam);
                let adminListUrlParam = `/${resource}/list?${adminListUrlSearch.toString()}`;
                return httpClient(adminListUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<Admin>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.adminId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'role':
                let roleListPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let roleListFilter = {...params.filter};
                let roleListParam = { ...roleListFilter, ...roleListPage };
                let roleListUrlSearch = new URLSearchParams(roleListParam);
                let roleListUrlParam = `/${resource}/list?${roleListUrlSearch.toString()}`;
                return httpClient(roleListUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<AdminRole>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.roleId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'permissions':
                let permissionsListPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                let permissionsListFilter = {...params.filter};
                let permissionsListParam = { ...permissionsListFilter, ...permissionsListPage };
                let permissionsListUrlSearch = new URLSearchParams(permissionsListParam);
                let permissionsListUrlParam = `/${resource}/list?${permissionsListUrlSearch.toString()}`;
                return httpClient(permissionsListUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<AdminPermission>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    let data = json.data.records.map(item => ({
                        id: item.permissionId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            default:
                return Promise.reject(`Unknown resource: ${resource}`);
        }
    },
    //@ts-ignore
    getOne: (resource: string, params: any) => {
        switch (resource) {
            case 'blogtags':
                let blogTagUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogTagUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogTag>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: json.data.tagId,
                            ...json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'admin':
                let adminGetOneUrlParam = `/${resource}/get?adminId=${params.id}`;
                return httpClient(adminGetOneUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<Admin>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: json.data.adminId,
                            ...json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'role':
                let roleGetOneUrlParam = `/${resource}/get?roleId=${params.id}`;
                return httpClient(roleGetOneUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<AdminRole>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: json.data.roleId,
                            ...json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'blogctrbitems':
                let blogCtrbItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogCtrbItemUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogCtrbItem>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    let data = json.data.videoLink ? {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                        videoFile: {
                            src: json.data.videoLink,
                            title: 'videoFile'
                        }
                    } : {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                    };
                    return {
                        data: data
                    };
                }).catch(error => Promise.reject(error));
            case 'blogitems':
                let blogItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogItemUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogItem>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    let data = json.data.videoLink ? {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                        videoFile: {
                            src: json.data.videoLink,
                            title: 'videoFile'
                        }
                    } : {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                    };
                    return {
                        data: data
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns/check':
                let mcampaignCheckUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(mcampaignCheckUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.id,
                            data:json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                let mcampaignUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(mcampaignUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaign>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    let data = {
                        ...json.data,
                        id: json.data.campaignId,
                        coverImageFile: {
                            src: json.data.coverImage,
                            title: 'coverImgFile'
                        },
                        startDate: formatDate(json.data.startDate.toString()),
                        endDate: formatDate(json.data.endDate.toString())
                    };
                    return {
                        data: data
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                let mcampaignLogUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(mcampaignLogUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaignLog>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: json.data.logId,
                            ...json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    //@ts-ignore
    delete: (resource: string, params: DeleteParams) => {
        switch (resource){
            case 'blogctrbitems':
                let blogCtrbItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogCtrbItemUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: json.data
                    };
                }).catch(error => Promise.reject(error));
            case 'blogitems':
                let blogItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogItemUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: json.data
                    };
                }).catch(error => Promise.reject(error));
            case 'admin':
                let adminDeleteUrlParam = `/${resource}/delete?adminId=${params.id}`;
                return httpClient(adminDeleteUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: json.data
                    };
                }).catch(error => Promise.reject(error));
            case 'role':
                let roleDeleteUrlParam = `/${resource}/delete?roleId=${params.id}`;
                return httpClient(roleDeleteUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: json.data
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    //@ts-ignore
    deleteMany: (resource: string, params: DeleteManyParams) => {
        switch (resource){
            case 'blogtags':
                let blogTagsUrlParam = `/api/${resource}?tagIds=${params.ids}`;
                return httpClient(blogTagsUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.ids}`);
                    }
                    return {
                        data: params.ids
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                let mcampaignLogsUrlParam = `/api/${resource}?logIds=${params.ids}`;
                return httpClient(mcampaignLogsUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.ids}`);
                    }
                    return {
                        data: params.ids
                    };
                }).catch(error => Promise.reject(error));

        }
    },
    //@ts-ignore
    update: (resource: string, params: UpdateParams) => {
        switch (resource){
            case 'blogctrbitems/review':
                let blogCtrbItemReviewUrlParam = `/api/${resource}`;
                return httpClient(blogCtrbItemReviewUrlParam,
                    {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with id ${params.id}`);
                    }
                    params.previousData.onList = params.data.status;
                    return {
                        data: params.previousData
                    };
                }).catch(error => Promise.reject(error));
            case 'blogitems/status':
                let blogItemStateUrlSearch = new URLSearchParams(params.data);
                let blogItemStatusUrlParam = `/api/${resource}?${blogItemStateUrlSearch.toString()}`;
                return httpClient(blogItemStatusUrlParam,
                    {
                        method: 'PUT',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with id ${params.id}`);
                    }
                    params.previousData.onList = params.data.status;
                    return {
                        data: params.previousData
                    };
                }).catch(error => Promise.reject(error));
            case 'blogtags':
                let blogItemsData = {...params.data}
                let blogItemsUrl = `/api/${resource}`;
                return httpClient(blogItemsUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(blogItemsData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: params.data
                    };
                }).catch(error => Promise.reject(error));
            case 'admin':
                let adminUpdateData = {...params.data}
                let adminUpdateUrl = `/${resource}/update`;
                return httpClient(adminUpdateUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(adminUpdateData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: params.data
                    };
                }).catch(error => Promise.reject(error));
            case 'admin/authorize':
                let adminAuthorizeUrlSearch = new URLSearchParams(params.data);
                let adminAuthorizeUpdateUrl = `/${resource}?${adminAuthorizeUrlSearch.toString()}`;
                return httpClient(adminAuthorizeUpdateUrl,
                    {
                        method: 'GET',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: params.data
                    };
                }).catch(error => Promise.reject(error));
            case 'role':
                let roleUpdateData = {...params.data}
                let roleUpdateUrl = `/${resource}/update`;
                return httpClient(roleUpdateUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(roleUpdateData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: params.data
                    };
                }).catch(error => Promise.reject(error));
            case 'blogctrbitems':
                let blogCtrbItemData = {...params.data}
                let blogCtrbItemFormData = new FormData();
                Object.keys(blogCtrbItemData).forEach(key => {
                    blogCtrbItemFormData.append(key, blogCtrbItemData[key]);
                });
                let blogCtrbItemUrl = `/api/${resource}/edit`;
                return httpClient(blogCtrbItemUrl,
                    {
                        method: 'POST',
                        body: blogCtrbItemFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.itemId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case "blogitems":
                let blogItemData = {...params.data}
                let blogItemFormData = new FormData();
                Object.keys(blogItemData).forEach(key => {
                    blogItemFormData.append(key, blogItemData[key]);
                });
                let blogItemUrl = `/api/${resource}/edit`;
                return httpClient(blogItemUrl,
                    {
                        method: 'POST',
                        body: blogItemFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.itemId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case "blogitems/references":
                let blogItemReferencesData = {...params.data}
                let blogItemReferencesPostData = {itemId: blogItemReferencesData.id, references: blogItemReferencesData.reference}
                let blogItemReferencesUrl = `/api/${resource}`;
                return httpClient(blogItemReferencesUrl,
                    {
                        method: 'POST',
                        body: JSON.stringify(blogItemReferencesPostData),
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.itemId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                let mcampaignData = {...params.data}
                let mcampaignFormData = new FormData();
                Object.keys(mcampaignData).forEach(key => {
                    mcampaignFormData.append(key, mcampaignData[key]);
                });
                let mcampaignUrl = `/api/${resource}/edit`;
                return httpClient(mcampaignUrl,
                    {
                        method: 'POST',
                        body: mcampaignFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.campaignId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                let mcampaignLogData = {...params.data}
                let mcampaignLogUrl = `/api/${resource}`;
                return httpClient(mcampaignLogUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(mcampaignLogData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.logId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    //@ts-ignore
    updateMany: (resource: string, params: UpdateManyParams) => {
        switch (resource){
            case 'blogtags/type':
                let blogTagsData = {...params.data}
                let blogTagsTypeUrl = `/api/${resource}`;
                return httpClient(blogTagsTypeUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(blogTagsData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.ids}`);
                    }
                    return {
                        data: params.ids
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    // @ts-ignore
    create: (resource: string, params: CreateParams) => {
        switch (resource){
            case 'blogtags':
                let blogTagsData = {...params.data}
                let blogTagsCreateUrl = `/api/${resource}`;
                return httpClient(blogTagsCreateUrl,
                    {
                        method: 'POST',
                        body: JSON.stringify(blogTagsData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogTag>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.tagId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'admin':
                let adminCreateData = {...params.data}
                let adminCreateUrl = `/${resource}/create`;
                return httpClient(adminCreateUrl,
                    {
                        method: 'POST',
                        body: JSON.stringify(adminCreateData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<Admin>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.adminId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'role':
                let permissionsCreateData = {...params.data}
                let permissionsCreateUrl = `/${resource}/create`;
                return httpClient(permissionsCreateUrl,
                    {
                        method: 'POST',
                        body: JSON.stringify(permissionsCreateData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<AdminRole>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.roleId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'blogctrbitems':
                let blogCtrbItemData = {...params.data}
                let blogCtrbItemFormData = new FormData();
                Object.keys(blogCtrbItemData).forEach(key => {
                    blogCtrbItemFormData.append(key, blogCtrbItemData[key]);
                });
                let blogCtrbItemCreateUrl = `/api/${resource}`;
                return httpClient(blogCtrbItemCreateUrl,
                    {
                        method: 'POST',
                        body: blogCtrbItemFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogCtrbItem>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.itemId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                let mcampaignData = {...params.data}
                let mcampaignFormData = new FormData();
                Object.keys(mcampaignData).forEach(key => {
                    mcampaignFormData.append(key, mcampaignData[key]);
                });
                let mcampaignCreateUrl = `/api/${resource}`;
                return httpClient(mcampaignCreateUrl,
                    {
                        method: 'POST',
                        body: mcampaignFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaign>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.campaignId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                let mcampaignLogData = {...params.data}
                let mcampaignLogCreateUrl = `/api/${resource}`;
                return httpClient(mcampaignLogCreateUrl,
                    {
                        method: 'POST',
                        body: JSON.stringify(mcampaignLogData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaignLog>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.logId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
        }
    }
}

export default customDataProvider;
