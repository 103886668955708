import * as React from 'react';
import { SVGProps } from 'react';
import {useStore} from 'react-admin';
import {ThemeName} from "../themes/themes";

const Logo = (props: SVGProps<SVGSVGElement>) => {
    const [themeName] = useStore<ThemeName>('themeName', 'default');
    return (
        themeName === 'default' ? (
            <img src="/images/logo-white.png" alt="捷可印"/>
            ) :(
            <img src="/images/logo.png" alt="捷可印"/>
        )
    );
};

export default Logo;
