import {
    CheckboxGroupInput,
    Edit, ListButton, Loading,
    required, SaveButton,
    SimpleForm,
    TextInput, Toolbar,
    useEditContext, useEditController, useGetList,
} from "react-admin";
import * as React from "react";
import {Card, CardContent, Grid} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import {useParams} from "react-router";


export const EditToolbar = () => {
    const { saving } = useEditContext();
    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="保存" size={"medium"}/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={saving} size={"medium"} label="返回" variant={"outlined"} icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}

const RoleEdit= () => {
    const { id } = useParams();
    const { record, isPending: editing } = useEditController({ resource: 'role', id });
    const { data, isPending, error } = useGetList(
        'permissions',
        {
            pagination: { page: 1, perPage: 1000 },
        }
    );
    if (isPending || editing) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const defaultPermissions = record.permissions.map((r: { permissionId: number; }) => r.permissionId);

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Edit mutationMode={"pessimistic"}>
                    <SimpleForm toolbar={<EditToolbar/>} warnWhenUnsavedChanges>
                        <TextInput source="roleName" label="角色名稱" validate={required("*角色名稱必須填寫")} name="roleName" />
                        <CheckboxGroupInput source="authIds" label="權限資源" choices={data} validate={required("*權限資源必須填寫")}
                                            optionText="permissionName" optionValue="permissionId" defaultValue={defaultPermissions}
                                            sx={{
                                                '& .MuiFormGroup-row':{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                                }
                                            }}
                        />
                    </SimpleForm>
                </Edit>
            </CardContent>
        </Card>
    );
}

export default RoleEdit;
