import {
    Admin,
    Resource,
    localStorageStore,
    useStore,
    StoreContextProvider,
} from 'react-admin';
import { Route } from 'react-router';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import { Layout, Login } from './layout';
import blogctrbitems from './blogctrbitems';
import blogitems from './blogitems';
import blogtags from './blogtags';
import mcampaigns from './mcampaigns';
import admin from './admin';
import adminrole from './role';
import { themes, ThemeName } from './themes/themes';
import MCampaignLogList from "./mcampaignlogs/MCampaignLogList";
import MCampaignLogCreate from "./mcampaignlogs/MCampaignLogCreate";
import MCampaignLogEdit from "./mcampaignlogs/MCampaignLogEdit";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import merge from 'lodash/merge';
import zhTWMessages from './i18n/zh-tw';


const store = localStorageStore(undefined, 'ECommerce');

const customZhTWMessages = {
    ra: {
        menu: {
            blogmanager: '部落格管理',
            blogctrbitems: '投稿文章',
            blogtagmanager: '標籤管理',
            blogitems: '入選文章',
            blogtags: '標籤管理',
            adminmanager: '用戶管理',
            admin: '用戶管理',
            role: '角色管理',
            mcampaignmanager: '行銷活動管理',
            mcampaigns: '行銷活動',
        }
    },
    pos: {action: {change_theme: '切換主題'}}
}

const messages = merge({}, zhTWMessages, customZhTWMessages);

const i18nProvider = polyglotI18nProvider(() => messages, 'zh-tw');

const App = () => {
    const [themeName] = useStore<ThemeName>('themeName', 'default');
    const lightTheme = themes.find(theme => theme.name === themeName)?.light;
    const darkTheme = themes.find(theme => theme.name === themeName)?.dark;
    return (
        <Admin
            dataProvider={dataProvider}
            store={store}
            authProvider={authProvider}
            loginPage={Login}
            layout={Layout}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme="light"
           i18nProvider={i18nProvider}
        >
            <Resource name="blogctrbitems" {...blogctrbitems} />
            <Resource name="blogitems" {...blogitems} />
            <Resource name="blogtags" {...blogtags} />
            <Resource name="admin" {...admin} />
            <Resource name="role" {...adminrole} />
            <Resource name="mcampaigns" {...mcampaigns}>
                <Route path=":id/mcampaignlogs" element={<MCampaignLogList/>} />
                <Route path=":id/mcampaignlogs/create" element={<MCampaignLogCreate/>} />
                <Route path=":id/mcampaignlogs/:logId" element={<MCampaignLogEdit/>} />
            </Resource>
        </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;
