import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    List,
    ListItem, ListItemIcon, ListItemText
} from "@mui/material";
import React, {useEffect} from "react";
import Paper, {PaperProps} from "@mui/material/Paper";
import Draggable from "react-draggable";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";
import PersonIcon from '@mui/icons-material/Person';
import {Loading, useGetList} from "react-admin";
import {AdminRole} from "../types";

type AuthorizeRoleDialogProps = {
    open: boolean;
    title: string;
    authIds: number[];
    handleClose: () => void;
    handleConfirm: (authIds: number[]) => void;
}

const PaperComponent = (props: PaperProps) => {
    const nodeRef = React.useRef<HTMLDivElement>(null);
    return (
        <Draggable
            nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

const AuthorizeRoleDialog = ({open, title, handleClose, handleConfirm, authIds}: AuthorizeRoleDialogProps) =>{
    const [checked, setChecked] = React.useState<number[]>(authIds);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
    const { data, isPending, error } = useGetList(
        'role',
        {
            pagination: { page: 1, perPage: 100 }
        }
    );

    const handleToggle = (value: number) => () => {
        if (checked.includes(value)) {
            // 如果已经选中，取消选中
            setChecked([]);
        } else {
            // 否则只选中当前项
            setChecked([value]);
        }
    };

    useEffect(() => {
        if(authIds.length != checked.length){
            setIsDisabled(false);
        } else {
            const arraysAreEqual = authIds.sort().every((value, index) => value === checked.sort()[index]);
            setIsDisabled(arraysAreEqual);
        }
    }, [checked,authIds]);

    const handleConfirmClick = () => {
        handleConfirm(checked);
    }


    if (isPending) { return <Loading />; }
    if (error) { return null; }

    return (
        open && (
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        選擇一個角色授權給此用戶，此用戶將擁有該角色的所有資源權限!
                    </DialogContentText>
                    <List sx={{ width: '100%', maxWidth: 400, margin: '0 auto', bgcolor: 'background.paper' }}>
                        {data.map((value: AdminRole) => {
                            const labelId = `checkbox-list-label-${value.roleId}`;
                            return (
                                <ListItem
                                    key={value.roleId}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="comments">
                                            <PersonIcon />
                                        </IconButton>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton role={'listitem'} onClick={handleToggle(value.roleId)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.includes(value.roleId)}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={`${value.roleName}`} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>取消</Button>
                    <Button disabled={isDisabled} onClick={handleConfirmClick}>確定</Button>
                </DialogActions>
            </Dialog>
        )
    );
}

export default AuthorizeRoleDialog;
