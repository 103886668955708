import {InputProps, useInput} from 'react-admin';
import {TextField} from '@mui/material';
import React, { lazy, Suspense} from "react";
import "toast-editor-style";

const ToastEditorWrapper = lazy(() => import("toast-editor").then(module => ({ default: module.ToastEditorWrapper })));

export type TipTapEditorInputProps = {
    defaultTxtContent?: string;
    onTextContent: (text: string) => void;
} & InputProps;

const ToastEditorInput = (props: TipTapEditorInputProps) => {
    const [isFullScreen, setIsFullScreen] = React.useState(false);
    const {defaultTxtContent, onTextContent} = props;
    const [isOneTime, setIsOneTime] = React.useState(true);
    // 使用 useInput 來獲取控制字段的各種方法和屬性
    const {
        field,        // field 包含了 value 和 onChange 方法
        fieldState,   // fieldState 包含了 isTouched, isDirty 等狀態
        formState,    // formState 包含整個表單的狀態
        id,
    } = useInput(props);

    // 處理手動設置值的函數
    const handleSetSrcContentValue = (content: string) => {
        if(isOneTime){
            setIsOneTime(false);
        } else if (content !== field.value && content !== defaultTxtContent) {
            field.onChange(content); // 使用 onChange 方法設置新值
        } else {
            field.onChange("");
        }
    };

    return (
        <div className={'w-full'}>
            <TextField
                {...field} // 繫結輸入的 value 和 onChange
                label={props.label}       // 傳遞 label
                error={!!fieldState.error} // 顯示錯誤
                helperText={fieldState.error?.message} // 顯示錯誤信息
                fullWidth
                value={defaultTxtContent || ''}
                style={{display: 'none'}}
            />
            <div className="flex w-full flex-col">
                <span className={`w-[80px] ${fieldState.error ? 'text-red-500' : 'text-gray-500'}`}>{props.label}*</span>
                <div className={`${
                    isFullScreen
                        ? 'fixed top-10 left-0 w-full h-full p-4 bg-white z-50'
                        : 'grow w-auto'
                }`}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <ToastEditorWrapper height={`${isFullScreen ? '80vh' : '600px'}`} key={defaultTxtContent} defaultMarkdown={defaultTxtContent || ''} getMarkdown={(m)=>handleSetSrcContentValue(m)}
                                            getFullScreen={(f)=>setIsFullScreen(f)}
                                            getHTML={(html)=>onTextContent(html)}/>
                    </Suspense>
                </div>
            </div>
            {(fieldState.error) && <span className={'text-red-500 text-xs'}>*必須填寫文章內文</span>}
        </div>
    );
};

export default ToastEditorInput;
