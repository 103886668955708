import {
    BooleanInput,
    Edit, email, ListButton, Loading, required,
    SaveButton, SimpleForm,
    TextInput,
    Toolbar, useEditController,
} from "react-admin";
import * as React from "react";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Grid} from "@mui/material";
import {useParams} from "react-router";


export const EditToolbar = ({resource, saving}: { resource: any, saving: boolean | undefined }) => {
    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton resource={"mcampaignlogs"} label="保存"/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={saving} size={"medium"} resource={resource} label="返回" variant={"outlined"}  icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const MCampaignLogEdit = () => {
    const {id, logId} = useParams();
    const { record, isPending, save,saving, error } = useEditController({
        resource: 'mcampaignlogs',
        id: logId,
        mutationMode: "pessimistic",
        redirect: `/mcampaigns/${id}/mcampaignlogs`
    });

    const validateURL = (value: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // 协议
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // 域名
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // 或 IP (v4) 地址
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // 端口和路径
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // 查询字符串
            '(\\#[-a-z\\d_]*)?$','i'); // hash标签
        if (!pattern.test(value)) {
            return '請輸入有效的鏈接地址';
        }
        return undefined;
    };

    if (isPending) { return <Loading />; }
    if (error || !id || !logId) { return <p>ERROR</p>; }

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Edit>
                    <SimpleForm record={record} onSubmit={save} toolbar={<EditToolbar resource={`/mcampaigns/${id}/mcampaignlogs`} saving={saving}/>} warnWhenUnsavedChanges >
                        <TextInput source="campaignId" label="活動編號" validate={required()} readOnly name="campaignId"/>
                        <TextInput source="LogId" label="日誌ID" readOnly name="logId" />
                        <TextInput source="realName" label="留言者真實姓名" validate={required()} name="realName"/>
                        <TextInput source="email" label="留言者email" validate={[required(),email()]} name="email"/>
                        <TextInput source="phoneNumber" label="留言者電話" validate={required()} name="phoneNumber"/>
                        <TextInput source="externalLink" label="活動網址" validate={[required(),validateURL]} name="externalLink"/>
                        <BooleanInput source="toTop" label="置頂該留言" validate={required()} name="toTop"/>
                        <TextInput source="leaveMessage" multiline resettable rows={5} label="留言內容" validate={required()} name="leaveMessage"/>
                    </SimpleForm>
                </Edit>
            </CardContent>
        </Card>
    );
};

export default MCampaignLogEdit;
