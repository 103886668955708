import * as React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FieldProps } from "ra-ui-materialui/src/field/types";
import {
    Confirm,
    useDelete,
    useNotify,
    usePermissions,
    useRecordContext,
    useRedirect,
    useRefresh,
    useUpdate
} from "react-admin";
import {Fragment, useState} from "react";
import {BlogItemStatusEnum} from "../components/BlogItemStatusField";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';

const BlogCtrbItemsRowActions = ({ source }: FieldProps) => {
    const record = useRecordContext();
    const {permissions} = usePermissions();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [delOpen, setDelOpen] = useState(false);
    const [selectedOpen, setSelectedOpen] = useState(false);
    const [reviewStatus, setReviewStatus] = useState<number>(0);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [deleteOne, { isPending: deleting}] = useDelete();

    const [update, { isPending: unselecting}] = useUpdate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        redirect(`/blogctrbitems/${record?.id}`)
        handleClose();
    };

    const handleSelected = (status: number)=>{
        setReviewStatus(status);
        setSelectedOpen(true);
        handleClose();
    }

    const handleDelete = () => {
        setDelOpen(true);
        handleClose();
    };

    const redenderStatus = (status: number) => {
        switch (status) {
            case BlogItemStatusEnum.AWAITING_REVIEW:
                return '待審核';
            case BlogItemStatusEnum.UNDER_REVIEW:
                return '審核中';
            case BlogItemStatusEnum.SELECTED:
                return '入選';
            case BlogItemStatusEnum.SELECTED_AND_PAID:
                return '入選且已匯款';
            case BlogItemStatusEnum.NOT_SELECTED_OR_RETURNED:
                return '未入選/退件';
            default:
                return 'Unknown';
        }
    }

    const handleDelClose = () => setDelOpen(false);
    const handleDelConfirm = () => {
        deleteOne('blogctrbitems', { id: record?.id},{
            onSuccess: () => {
                refresh();
                setDelOpen(false);
            },
            onError: () => {
                setDelOpen(false);
            }
        });
    };

    const handleSelectedClose = () => setSelectedOpen(false);
    const handleSelectedConfirm = () => {
        update('blogctrbitems/review', {
            id: record?.id,
            data: {itemId: record?.id, status: reviewStatus},
            previousData: record
        }, {
            onSuccess: () => {
                refresh();
                setSelectedOpen(false);
            },
            onError: (error) => {
                setSelectedOpen(false);
                notify(error.message, {type : 'error'});
            }
        })
    };

    return (
        <Fragment>
            <IconButton aria-label="more" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {permissions && (permissions.includes('sys_edit_blogctrbitems')) && <MenuItem onClick={handleEdit} disabled={record?.status > 100}><EditOutlinedIcon/>編輯</MenuItem>}
                {
                    (permissions && permissions.includes('sys_review_blogctrbitems')) && (
                        <>
                            <div className={'w-full border-b border-gray-300'}></div>
                            <MenuItem onClick={()=>{handleSelected(1)}} disabled={record?.status === 1 || record?.status > 100}><InventoryOutlinedIcon/>審核中</MenuItem>
                            <div className={'w-full border-b border-gray-300'}></div>
                            <MenuItem onClick={()=>{handleSelected(101)}} disabled={record?.status === 101 || record?.status > 100}><CheckOutlinedIcon/>入選</MenuItem>
                            <div className={'w-full border-b border-gray-300'}></div>
                            <MenuItem onClick={()=>{handleSelected(102)}} disabled={record?.status === 102 || record?.status > 100}><PriceCheckOutlinedIcon/>入選且已匯款</MenuItem>
                            <div className={'w-full border-b border-gray-300'}></div>
                            <MenuItem onClick={()=>{handleSelected(-1)}} disabled={record?.status === -1 || record?.status > 100}><CommentsDisabledOutlinedIcon/>未入選/退件</MenuItem>
                            <div className={'w-full border-b border-gray-300'}></div>
                        </>
                    )
                }
                {(permissions && permissions.includes('sys_detele_blogctrbitems')) && <MenuItem onClick={handleDelete} disabled={record?.status > 100}><DeleteOutlineOutlinedIcon/>删除</MenuItem>}
            </Menu>
            <Confirm
                isOpen={delOpen}
                loading={deleting}
                title={`删除 ${record?.id}`}
                content="您確定想要刪除該投稿文章?"
                onConfirm={handleDelConfirm}
                onClose={handleDelClose}
            />
            <Confirm
                isOpen={selectedOpen}
                loading={unselecting}
                title={`審核 ${record?.id}`}
                content={`您確定想要審核該投稿文章為: ${redenderStatus(reviewStatus)}?`}
                onConfirm={handleSelectedConfirm}
                onClose={handleSelectedClose}
            />
        </Fragment>
    );
};

export default BlogCtrbItemsRowActions;
