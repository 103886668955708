import * as React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FieldProps } from "ra-ui-materialui/src/field/types";
import {
    Confirm, Loading, useDelete,
    useNotify, usePermissions,
    useRecordContext,
    useRedirect,
    useRefresh, useUpdate
} from "react-admin";
import {useState} from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AuthorizeRoleDialog from "../components/AuthorizeRoleDialog";

const AdminRowActions = ({ source }: FieldProps) => {
    const record = useRecordContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [delOpen, setDelOpen] = useState(false);
    const [authOpen, setAuthOpen] = useState(false);
    const refresh = useRefresh();
    const redirect = useRedirect();
    const  notify = useNotify();
    const {permissions} = usePermissions();
    const [currentAuthId] = useState<number[]>(record?.authRole === -1 ? [] : [record?.authRole]);
    const [remove, { isPending: deleting }] = useDelete(
        'admin',
        { id: record?.id }
    );
    const [update, { isPending}] = useUpdate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        redirect(`/admin/${record?.id}`)
        handleClose();
    };

    const handleDelete = () => {
        setDelOpen(true);
        handleClose();
    };

    const handleDelClose = () => setDelOpen(false);
    const handleDelConfirm = () => {
        remove('admin', { id: record?.id }, {
            onSuccess: () => {
                refresh();
                setDelOpen(false);
            },
            onError: (error) => {
                setDelOpen(false);
            },
        });
    };

    const handleAuthClose = () => setAuthOpen(false);
    const handleAuthorize = () => {
        setAuthOpen(true);
        handleClose();
    }

    const handleAuthConfirm = (authIds: number[]) => {
        update('admin/authorize', { id: record?.id, data: {id: record?.id,adminId: record?.adminId, roleId: authIds[0]}, previousData: record }, {
            onSuccess: () => {
                refresh();
                setAuthOpen(false);
            },
            onError: (error) => {
                setAuthOpen(false);
                notify(error.message, { type: 'error' });
            },
        });
    }

    if(isPending) return <Loading />;

    return (
        <>
            <IconButton aria-label="more" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {(permissions && permissions.includes('sys_edit_admin')) && <MenuItem onClick={handleEdit}><EditOutlinedIcon/>編輯</MenuItem>}
                {(permissions && permissions.includes('sys_authorize_admin')) && (
                    <>
                        <div className={'w-full border-b border-gray-300'}></div>
                        <MenuItem onClick={handleAuthorize}><PersonAddAltIcon/>授權角色</MenuItem>
                    </>
                )}
                <div className={'w-full border-b border-gray-300'}></div>
                {permissions && (permissions.includes('sys_delete_admin')) && <MenuItem onClick={handleDelete} disabled={record?.onList > 0}><DeleteOutlineOutlinedIcon/>删除</MenuItem>}
            </Menu>
            <Confirm
                isOpen={delOpen}
                loading={deleting}
                title={`删除 ${record?.id}`}
                content="您確定想要刪除該用戶嗎?"
                onConfirm={handleDelConfirm}
                onClose={handleDelClose}
            />
            <AuthorizeRoleDialog authIds={currentAuthId} open={authOpen}
                                 title={`授予「${record?.id}」角色權限`}
                                 handleClose={handleAuthClose}
                                 handleConfirm={handleAuthConfirm}  />
        </>
    );
};

export default AdminRowActions;
