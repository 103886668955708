import {
    Button,
    Create, DateInput, FormDataConsumer, ImageField, ImageInput, ListButton, required,
    SaveButton, SimpleForm,
    TextInput,
    Toolbar, useCreateContext, useDataProvider, useNotify,
} from "react-admin";
import * as React from "react";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Grid} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {FieldValues} from "react-hook-form";


export const CreateToolbar = () => {
    const { isPending } = useCreateContext();

    const transformMCampaignData = (data: any) => {
        return {
            ...data,
            coverImageFile: data.coverImageFile.rawFile,
            startDate: formatDate(new Date(data.startDate)),
            endDate: formatDate(new Date(data.endDate))
        }
    }

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 月份从0开始，所以加1
        const day = date.getDate();

        // 使用 `padStart` 确保月和日始终是两位数
        const monthFormatted = month.toString().padStart(2, '0');
        const dayFormatted = day.toString().padStart(2, '0');

        return `${year}${monthFormatted}${dayFormatted}`;
    }

    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="保存" transform={transformMCampaignData}   type="button"/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={isPending} size={"medium"} label="返回" variant={"outlined"}  icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const MCampaignCreate = () => {
    const [uniqueIdStatus, setUniqueIdStatus] = React.useState<number>(0);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleUniqueId = (campaignId: number) => {
        dataProvider.getOne('mcampaigns/check', { id: campaignId })
            .then(({  data }) => {
                if (data.data) {
                    setUniqueIdStatus(2);
                } else {
                    setUniqueIdStatus(1);
                }
            })
            .catch(error => {
                notify(`Error: ${error.message}`, {type: 'error'});
            });
    }

    const validateURL = (value: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // 协议
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // 域名
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // 或 IP (v4) 地址
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // 端口和路径
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // 查询字符串
            '(\\#[-a-z\\d_]*)?$','i'); // hash标签
        if (!pattern.test(value)) {
            return '請輸入有效的鏈接地址';
        }
        return undefined;
    };

    const handleValidate = (values: FieldValues) => {
        const errors: FieldValues = {};
        if (!values.campaignId) {
            errors.campaignId = '活動編號必須填寫';
            return errors;
        }
        if (values.campaignId && parseInt(values.campaignId, 10) != values.campaignId) {
            errors.campaignId = '活動編號必須是數字';
            return errors;
        }
        if (uniqueIdStatus === 0) {
            errors.campaignId = '請檢查活動編號是否可用';
            return errors;
        }
        if (uniqueIdStatus > 1) {
            errors.campaignId = '活動編號已存在';
            return errors;
        }
        if (!values.name) {
            errors.name = '活動名稱必須填寫';
        }
        if (!values.description) {
            errors.description = '活動敘述必須填寫';
        }
        if (!values.coverImageFile) {
            errors.coverImageFile = '活動封面圖必須上傳';
        }
        if (!values.pageLink) {
            errors.pageLink = '活動網址必須填寫';
        }
        if (!values.startDate) {
            errors.startDate = '活動開始日期必須填寫';
        }
        if (!values.endDate) {
            errors.endDate = '活動結束日期必須填寫';
        }
        return errors;
    }

    const handleCampaignIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUniqueIdStatus(0)
    }

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Create redirect={"list"}>
                    <SimpleForm toolbar={<CreateToolbar/>} validate={handleValidate} warnWhenUnsavedChanges>
                        <Grid container>
                            <Grid item={true} xs={6} >
                                <TextInput source="campaignId" label="活動編號" validate={required()} name="campaignId" onChange={handleCampaignIdChange}/>
                            </Grid>
                            <Grid item={true} xs={5} sx={{ml: '1em',mt: '1em'}}>
                                <FormDataConsumer>
                                    {({formData, ...rest}) => {
                                        return (
                                            <Button label={uniqueIdStatus === 0 ? '是否可用？' : (uniqueIdStatus === 1 ? '可用' : '不可用')} type={"button"} onClick={() => handleUniqueId(formData.campaignId)}
                                                    disabled={!formData.campaignId || uniqueIdStatus === 1} color={'secondary'} size={"small"}
                                                    variant={"outlined"}/>
                                        )
                                    }}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                        <TextInput source="name" label="活動名稱" validate={required()} name="name"/>
                        <TextInput source="description" label="活動敘述" validate={required()} name="description"/>
                        <div className={'border'}>
                            <ImageInput source="coverImageFile" name="coverImageFile"
                                        label={
                                            <p className={'text-lg text-red-400 m-2'}>*活動封面圖(最小尺寸為[312x160px]支持JPG、JPEG、PNG、WEBP檔案大小限製:3MB)</p>
                                        }
                                        validate={required("*必須上傳活動封面圖")}
                                        placeholder={<p><FileUploadOutlinedIcon/>上傳檔案</p>}>
                                <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: '100%', objectFit: 'contain' } }}  source="src" title="title"/>
                            </ImageInput>
                        </div>
                        <TextInput source="pageLink" label="活動網址" validate={[required(),validateURL]} name="pageLink"/>
                        <div className={'flex flex-1 w-full'}>
                            <DateInput source="startDate" name="startDate" label="活動開始日期" sx={{width: 200}} validate={required()} />
                            <span className={'mx-5 self-center font-bold'}>-</span>
                            <DateInput source="endDate" name="endDate" label="活動結束日期" sx={{width: 200}} validate={required()} />
                        </div>
                        <Button label="匯入用戶留言" color={"secondary"} size={"medium"} variant={"outlined"} startIcon={<AddOutlinedIcon/>}/>
                    </SimpleForm>
                </Create>
            </CardContent>
        </Card>
    );
};

export default MCampaignCreate;
