import {
    BooleanField, BulkDeleteButton, CreateButton,
    DatagridConfigurable,
    DateField, EditButton, email, EmailField, FilterButton,
    List, ListButton, Loading, SearchInput, SelectColumnsButton,
    TextField, TextInput, TopToolbar, UrlField, useListContext, usePermissions
} from "react-admin";
import * as React from "react";
import {Theme, useMediaQuery} from "@mui/material";
import {Fragment} from "react";
import MobileGrid from "../orders/MobileGrid";
import {useParams} from "react-router";
import UndoIcon from '@mui/icons-material/Undo';

const ListActions = ({ resource }: {resource: string}) => {
    return (
        <TopToolbar>
            <ListButton resource={"mcampaigns"} label="回退" icon={<UndoIcon/>}/>
            <CreateButton resource={resource} label={'新增留言'}/>
            <FilterButton/>
            <SelectColumnsButton />
        </TopToolbar>
    );
}

const PostList = () => {
    const {id} = useParams();
    if (!id) { return <p>ERROR</p>; }

    return (
        <List
            resource={"mcampaignlogs"}
            sort={{ field: 'createTime', order: 'DESC' }}
            perPage={10}
            filter={{campaignId: id}}
            filters={orderFilters}
            actions={<ListActions resource={`/mcampaigns/${id}/mcampaignlogs`}/>}
        >
            <TabbedDatagrid />
        </List>
    );
};

const orderFilters = [
    <SearchInput name="realName" source="realName" alwaysOn placeholder="留言者真實姓名" key="realName" />,
    <TextInput name="email" source="email" placeholder="留言者EMail" key="email" label="留言者email" validate={email()} />,
    <TextInput name="leaveMessage" source="leaveMessage" placeholder="留言內容" key="leaveMessage" label="留言內容" />,
];

const CustomBulkActionButtons = ({permissions}: {permissions: string[]}) => {
    return (
        (permissions && permissions.includes('sys_delete_mcampaignlogs')) && <BulkDeleteButton mutationMode="pessimistic" label={'刪除留言'} variant={"outlined"}
                                                      confirmTitle="刪除選中留言"
                                                      confirmContent="您確定要刪除選中的留言嗎？"
        />
    );
}

const TabbedDatagrid = () => {
    const {permissions, isPending: isLoading} = usePermissions();
    const {id} = useParams();
    const listContext = useListContext();
    const {isPending, error } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    if (isPending || isLoading) { return <Loading />; }
    if (error || !id) { return <p>ERROR</p>; }

    return (
        <Fragment>
            {isXSmall ? (
                <MobileGrid />
            ) : (
                <DatagridConfigurable rowClick={false}
                                      bulkActionButtons={<CustomBulkActionButtons permissions={permissions}/>}
                                      omit={['leaveMessage', 'phoneNumber', 'externalLink']}
                >
                    <TextField source="realName" label="留言者真實姓名"/>
                    <EmailField source="email" label="留言者email"/>
                    <BooleanField source="toTop" color={"red"} label="是否置頂留言"/>
                    <DateField source="createTime" showTime label="留言時間"/>
                    <TextField source="leaveMessage" label="留言內容"/>
                    <TextField source="phoneNumber" label="留言者移動號碼"/>
                    <UrlField source="externalLink" label="外部連結"/>
                    {(permissions && permissions.includes('sys_edit_mcampaignlogs')) && <EditButton resource={`/mcampaigns/${id}/mcampaignlogs`} label="編輯" />}
                </DatagridConfigurable>
            )}
        </Fragment>
    );
};

export default PostList;
