import {
    AutocompleteArrayInput,
    Edit, ListButton, Loading,
    required, SaveButton,
    SelectInput,
    SimpleForm,
    TextInput, Toolbar,
    useEditContext, useGetList,
} from "react-admin";
import * as React from "react";
import {Card, CardContent, Grid} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";


export const EditToolbar = () => {
    const { saving } = useEditContext();
    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="保存" size={"medium"}/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={saving} size={"medium"} label="返回" variant={"outlined"} icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}

const BlogTagsEdit= () => {
    const { data, isPending, error, error: editorError } = useGetList(
        'blogtags',
        {
            pagination: { page: 1, perPage: 1000 }
        }
    );
    if (isPending) { return <Loading />; }
    if (editorError || error) { return <p>ERROR</p>; }

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Edit mutationMode={"pessimistic"}>
                    <SimpleForm toolbar={<EditToolbar/>} warnWhenUnsavedChanges>
                        <TextInput source="tagId" label="標簽ID" validate={required()} readOnly  name="tagId"/>
                        <TextInput source="tagName" label="中文標籤名" validate={required()} name="tagName" />
                        <SelectInput source="type" label="標簽類型" name="type"
                                     choices={[
                                         { id: 0, name: '一般標簽' },
                                         { id: 1000, name: '熱門標簽' },
                                     ]} validate={required()} />
                        <AutocompleteArrayInput source="relatedTags" label="關聯標簽(最多10組)" optionValue="tagId" optionText="tagName" choices={data}  name="relatedTags"/>
                    </SimpleForm>
                </Edit>
            </CardContent>
        </Card>
    );
}

export default BlogTagsEdit;
