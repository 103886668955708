import * as React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import {
    useTranslate,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import SubMenu from './SubMenu';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name={translate('ra.menu.blogmanager')}
                icon={<EventNoteOutlinedIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/blogctrbitems"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('ra.menu.blogctrbitems')}
                    leftIcon={<EventNoteOutlinedIcon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/blogitems"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('ra.menu.blogitems')}
                    leftIcon={<LibraryBooksIcon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name={translate('ra.menu.blogtagmanager')}
                icon={<LoyaltyIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/blogtags"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('ra.menu.blogtags')}
                    leftIcon={<LoyaltyIcon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name={translate('ra.menu.adminmanager')}
                icon={<AccountCircleIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/admin"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('ra.menu.admin')}
                    leftIcon={<AccountCircleIcon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/role"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('ra.menu.role')}
                    leftIcon={<PeopleIcon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name={translate('ra.menu.mcampaignmanager')}
                icon={<CampaignOutlinedIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/mcampaigns"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('ra.menu.mcampaigns')}
                    leftIcon={<CampaignOutlinedIcon />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;
