import React, {Fragment} from 'react';
import {
    CreateButton,
    DatagridConfigurable, DeleteWithConfirmButton,
    EditButton, FilterButton,
    List,
    Loading,
    SearchInput, SelectColumnsButton,
    TextField,
    TopToolbar, useListContext, usePermissions,
} from 'react-admin';
import {Theme, useMediaQuery} from "@mui/material";
import MobileGrid from "../orders/MobileGrid";
import AdminRolePermissionsField from "../components/AdminRolePermissionsField";

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="新增角色" />
        <FilterButton />
        <SelectColumnsButton/>
    </TopToolbar>
);

const tagFilters = [
    <SearchInput source="roleName" alwaysOn placeholder="角色名稱"  name="roleName" key="roleName" />,
];

const RoleList = () =>{
    return (
        <List
            sort={{ field: 'roleId', order: 'DESC' }}
            perPage={10}
            actions={<ListActions />}
            filters={tagFilters}>
            <AdminListDatagrid />
        </List>
    )
}

const AdminListDatagrid = () => {
    const { permissions, isPending: isLoading } = usePermissions();
    const {isPending, error} = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    if (isPending || isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Fragment>
            {isXSmall ? (
                <MobileGrid />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick={false}>
                    <TextField source="roleId" label="角色Id"/>
                    <TextField source="roleName" label="角色名稱"/>
                    <AdminRolePermissionsField source="permissions" label="角色權限" />
                    <EditButton />
                    {(permissions && permissions.includes('sys_delete_role')) && <DeleteWithConfirmButton confirmColor="warning" />}
                </DatagridConfigurable>
            )
            }
        </Fragment>
    );
};

export default RoleList;
