import {
    CheckboxGroupInput,
    Create, ListButton, Loading,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar, useCreateContext, useGetList,
} from "react-admin";
import * as React from "react";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Grid} from "@mui/material";


export const CreateToolbar = () => {
    const { isPending } = useCreateContext();
    if (isPending) return <Loading />;
    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="保存" />
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={isPending} size={"medium"} label="返回" variant={"outlined"}  icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const RoleCreate = () => {
    const { data, isPending, error } = useGetList(
        'permissions',
        {
            pagination: { page: 1, perPage: 1000 },
        }
    );
    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Create redirect={"list"}>
                    <SimpleForm toolbar={<CreateToolbar/>} warnWhenUnsavedChanges>
                        <TextInput source="roleName" label="角色名稱" validate={required("*角色名稱必須填寫")} name="roleName" />
                        <CheckboxGroupInput source="authIds" label="權限資源" choices={data} validate={required("*權限資源必須填寫")}
                                            optionText="permissionName" optionValue="permissionId"
                                            sx={{
                                                '& .MuiFormGroup-row':{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                                }
                                            }}
                        />
                    </SimpleForm>
                </Create>
            </CardContent>
        </Card>
    );
};

export default RoleCreate;
