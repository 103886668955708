import {
    Button,
    DateInput, Edit, ImageField, ImageInput, ListButton, Loading, required,
    SaveButton, SimpleForm,
    TextInput,
    Toolbar, useEditContext, usePermissions,
} from "react-admin";
import * as React from "react";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Grid} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {FieldValues} from "react-hook-form";


export const CreateToolbar = () => {
    const { saving } = useEditContext();

    const transformMCampaignData = (data: any) => {
        return data.coverImageFile?.rawFile ? {
            campaignId: data.campaignId,
            name: data.name,
            description: data.description,
            pageLink: data.pageLink,
            coverImageFile: data.coverImageFile.rawFile,
            startDate: formatDate(new Date(data.startDate)),
            endDate: formatDate(new Date(data.endDate))
        } : {
            campaignId: data.campaignId,
            name: data.name,
            description: data.description,
            pageLink: data.pageLink,
            startDate: formatDate(new Date(data.startDate)),
            endDate: formatDate(new Date(data.endDate))
        }
    }

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 月份从0开始，所以加1
        const day = date.getDate();

        // 使用 `padStart` 确保月和日始终是两位数
        const monthFormatted = month.toString().padStart(2, '0');
        const dayFormatted = day.toString().padStart(2, '0');

        return `${year}${monthFormatted}${dayFormatted}`;
    }

    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="保存" transform={transformMCampaignData}  type="button"/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={saving} size={"medium"} label="返回" variant={"outlined"}  icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const MCampaignEdit = () => {
    const {permissions, isPending} = usePermissions();
    if (isPending) return <Loading/>;

    const validateURL = (value: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // 协议
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // 域名
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // 或 IP (v4) 地址
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // 端口和路径
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // 查询字符串
            '(\\#[-a-z\\d_]*)?$','i'); // hash标签
        if (!pattern.test(value)) {
            return '請輸入有效的鏈接地址';
        }
        return undefined;
    };

    const handleValidate = (values: FieldValues) => {
        const errors: FieldValues = {};
        if (!values.campaignId) {
            errors.campaignId = 'The campaignId is required';
            return errors;
        }
        if (values.campaignId && parseInt(values.campaignId, 10) != values.campaignId) {
            errors.campaignId = 'The campaignId is number';
            return errors;
        }
        if (!values.name) {
            errors.name = 'The name is required';
        }
        if (!values.description) {
            errors.description = 'The description is required';
        }
        if (!values.coverImageFile) {
            errors.coverImageFile = 'The coverImageFile is required';
        }
        if (!values.pageLink) {
            errors.pageLink = 'The pageLink is required';
        }
        if (!values.startDate) {
            errors.startDate = 'The startDate is required';
        }
        if (!values.endDate) {
            errors.endDate = 'The endDate is required';
        }
        return errors;
    }


    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Edit mutationMode={"pessimistic"}>
                    <SimpleForm toolbar={<CreateToolbar/>} validate={handleValidate} warnWhenUnsavedChanges>
                        <TextInput source="campaignId" label="活動編號" validate={required()} name="campaignId" readOnly/>
                        <TextInput source="name" label="活動名稱" validate={required()} name="name"/>
                        <TextInput source="description" label="活動敘述" validate={required()} name="description"/>
                        <div className={'border'}>
                            <ImageInput source="coverImageFile" name="coverImageFile"
                                        label={
                                            <p className={'text-lg text-red-400 m-2'}>*活動封面圖(最小尺寸為[312x160px]支持JPG、JPEG、PNG、WEBP檔案大小限製:3MB)</p>
                                        }
                                        validate={required("*必須上傳封面圖")}
                                        placeholder={<p><FileUploadOutlinedIcon/>上傳檔案</p>}>
                                <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: '100%', objectFit: 'contain' } }}  source="src" title="title"/>
                            </ImageInput>
                        </div>
                        <TextInput source="pageLink" label="活動網址" validate={[required(),validateURL]} name="pageLink"/>
                        <DateInput source="startDate" name="startDate" label="活動開始日期" validate={required()}/>
                        <DateInput source="endDate" name="endDate" label="活動結束日期" validate={required()}/>
                        {(permissions && permissions.includes('sys_import_mcampaignlogs')) && <Button label="匯入用戶留言" color={"secondary"} size={"medium"} variant={"outlined"} startIcon={<AddOutlinedIcon/>}/>}
                    </SimpleForm>
                </Edit>
            </CardContent>
        </Card>
    );
};

export default MCampaignEdit;
