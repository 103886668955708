import {FieldProps} from "ra-ui-materialui/src/field/types";
import {useRecordContext} from "react-admin";
import {Chip, Stack} from "@mui/material";
import React from "react";
import {AdminPermission} from "../types";

const AdminRolePermissionsField =({source}: FieldProps) => {
    const record = useRecordContext();
    const permissions = record?.permissions;
    return permissions ? (
        permissions.length <= 5 ? (
            <Stack direction="row" spacing={1}>
                {
                    permissions.map((permission: AdminPermission) => (<Chip color={"secondary"} key={permission.permissionId} label={permission.permissionName} />))
                }
            </Stack>
        ) : (
            <Stack direction="row" spacing={1}>
                {
                    permissions.slice(0,5).map((permission: AdminPermission) => (<Chip color={"secondary"} key={permission.permissionId} label={permission.permissionName} />))
                }
                <div className={'text-2xl font-black text-blue-500'}>...</div>
            </Stack>
        )
    ) : null;

}

export default AdminRolePermissionsField;
