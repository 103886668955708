import {
    AutocompleteArrayInput,
    Create,
    FileField,
    FileInput,
    ImageField,
    ImageInput,
    ListButton,
    Loading, required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar, useCreateContext,
    useGetIdentity,
    useGetList
} from "react-admin";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Divider, Grid, Tab, Tabs} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";
import {BlogItemContentTypeEnum} from "../components/BlogItemContentTypeField";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import ToastEditorInput from "../components/ToastEditorInput";

const BlogCtrbItemToolbar = ({category, contentType, textContent}: {category: number, textContent: string, contentType: number}) =>{
    const { data: user } = useGetIdentity();
    const { isPending } = useCreateContext();

    const transformBlogCtrbItemData = useCallback((data: any) =>{
        if(contentType === BlogItemContentTypeEnum.NOTE){
            return {
                ...data,
                mrscId: user?.id,
                email: user?.email,
                category: category,
                coverImgFile: data.coverImgFile?.rawFile,
                contentType: BlogItemContentTypeEnum.NOTE,
                txtContent: textContent,
            }
        } else if(contentType === BlogItemContentTypeEnum.WEBLINK){
            return {
                ...data,
                mrscId: user?.id,
                email: user?.email,
                category: category,
                coverImgFile: data.coverImgFile?.rawFile,
                contentType: BlogItemContentTypeEnum.WEBLINK,
                srcContent: data.txtContent,
            }
        } else if(contentType === BlogItemContentTypeEnum.VIDEO){
            return {
                ...data,
                mrscId: user?.id,
                email: user?.email,
                category: category,
                coverImgFile: data.coverImgFile?.rawFile,
                videoFile: data.videoFile?.rawFile,
                contentType: BlogItemContentTypeEnum.VIDEO,
                srcContent: data.txtContent,
            }
        } else if(contentType === BlogItemContentTypeEnum.YOUTUBE) {
            return {
                ...data,
                mrscId: user?.id,
                email: user?.email,
                category: category,
                coverImgFile: data.coverImgFile?.rawFile,
                contentType: BlogItemContentTypeEnum.YOUTUBE,
                srcContent: data.txtContent,
            }
        }
    },[contentType, category, textContent, user?.id, user?.email])

    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton transform={transformBlogCtrbItemData} label="保存"  type="button"/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton size={"medium"} label="返回" disabled={isPending} icon={<UndoIcon/>} variant={"outlined"} />
                </Grid>
            </Grid>
        </Toolbar>
    )
};

const BlogItemContentTypeTabs = [
    { id: 1, name: '文章內容', icon: <EventNoteOutlinedIcon/> },
    { id: 2, name: '書籤分享', icon: <AddLinkOutlinedIcon/> },
    { id: 3, name: '影片分享', icon: <VideoLibraryOutlinedIcon/> },
    { id: 4, name: 'YouTube', icon: <SmartDisplayOutlinedIcon/> }
];

const BlogItemCategoryTabs = [
    { id: 1, name: '部落格', icon: <BookOutlinedIcon/> },
    { id: 2, name: '工具分享', icon: <BuildCircleOutlinedIcon/> },
    { id: 3, name: '設計開箱', icon: <HomeRepairServiceOutlinedIcon/> }
];

const BlogCtrbItemCreate =() =>{
    const { data, isPending, error } = useGetList(
        'blogtags',
        {
            pagination: { page: 1, perPage: 1000 }
        }
    );
    const [categoryTabIdx, setCategoryTabIdx] = React.useState(1);
    const [contentTypeTabIdx, setContentTypeTabIdx] = React.useState(1);
    const [textContent, setTextContent] = React.useState<string>('');

    const categoryTabHandleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setCategoryTabIdx(value);
        },
        [setCategoryTabIdx]
    );

    const contentTypeTabHandleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setContentTypeTabIdx(value);
        },
        [setContentTypeTabIdx]
    );

    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const validateURL = (value: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // 协议
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // 域名
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // 或 IP (v4) 地址
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // 端口和路径
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // 查询字符串
            '(\\#[-a-z\\d_]*)?$','i'); // hash标签
        if (!pattern.test(value)) {
            return '請輸入有效的鏈接地址';
        }
        return undefined;
    };

    return (
        <Card sx={{mt: 2}}>
            <Tabs
                variant="fullWidth"
                centered
                value={categoryTabIdx}
                indicatorColor="primary"
                onChange={categoryTabHandleChange}
            >
                { BlogItemCategoryTabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span style={{display: 'flex',alignItems: 'center'}}>
                                {choice.icon}
                                {choice.name}
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            <CardContent>
                <Create>
                    <Tabs
                        variant="fullWidth"
                        centered
                        value={contentTypeTabIdx}
                        indicatorColor="primary"
                        onChange={contentTypeTabHandleChange}
                    >
                        { BlogItemContentTypeTabs.map(choice => (
                            <Tab
                                key={choice.id}
                                label={
                                    <span style={{display: 'flex',alignItems: 'center'}}>
                                {choice.icon}
                                        {choice.name}
                            </span>
                                }
                                value={choice.id}
                            />
                        ))}
                    </Tabs>
                    <Divider/>
                    {
                        contentTypeTabIdx === 1 && (
                            <SimpleForm  toolbar={<BlogCtrbItemToolbar category={categoryTabIdx} contentType={contentTypeTabIdx} textContent={textContent}/>} warnWhenUnsavedChanges>
                                <TextInput name="title"  source="title" label="文章標題" validate={required("*必須填寫文章標題")} multiline
                                           resettable/>
                                <ToastEditorInput source="srcContent" label="文章內容" validate={required("*必須填寫文章內文")} name="srcContent"
                                                  onTextContent={(content) => setTextContent(content)}/>
                                <AutocompleteArrayInput source="tags" label="標簽(最多10組)" optionValue="tagId" optionText="tagName" choices={data}  name="tags"/>
                                <div className={'border'}>
                                    <ImageInput source="coverImgFile" name="coverImgFile"
                                                label={
                                                    <span className={'text-lg text-red-400 m-2'}>*文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG、WEBP檔案大小限製:3MB)</span>
                                                }
                                                validate={required("*必須上傳封面圖")}
                                                placeholder={<div><FileUploadOutlinedIcon/>上傳檔案</div>}>
                                        <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: '100%', objectFit: 'contain' } }}  source="src" title="title"/>
                                    </ImageInput>
                                </div>
                            </SimpleForm>
                        )
                    }
                    {
                        contentTypeTabIdx === 2 && (
                            <SimpleForm  toolbar={<BlogCtrbItemToolbar textContent={textContent} category={categoryTabIdx} contentType={contentTypeTabIdx}/>} warnWhenUnsavedChanges>
                                <TextInput name="title" source="title" label="文章標題" validate={required("*必須填寫文章標題")} multiline
                                           resettable/>
                                <TextInput name="weblink" source="weblink" label="書籤連結"
                                           validate={[required("*必須填寫書籤連結"), validateURL]}/>
                                <TextInput multiline rows={7} name="txtContent" resettable source="txtContent" label="書籤說明"
                                           validate={required("*必須填寫書籤說明")}/>
                                <AutocompleteArrayInput source="tags" label="標簽(最多10組)" optionValue="tagId" optionText="tagName" choices={data}  name="tags"/>
                                <div className={'border'}>
                                    <ImageInput source="coverImgFile" name="coverImgFile"
                                                label={
                                                    <span className={'text-lg text-red-400 m-2'}>*文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG、WEBP檔案大小限製:3MB)</span>
                                                }
                                                validate={required("*必須上傳封面圖")}
                                                placeholder={<div><FileUploadOutlinedIcon/>上傳檔案</div>}>
                                        <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: '100%', objectFit: 'contain' } }}  source="src" title="title"/>
                                    </ImageInput>
                                </div>
                            </SimpleForm>
                        )
                    }
                    {
                        contentTypeTabIdx === 3 && (
                            <SimpleForm  toolbar={<BlogCtrbItemToolbar textContent={textContent} category={categoryTabIdx} contentType={contentTypeTabIdx}/>} warnWhenUnsavedChanges>
                                <TextInput name="title" source="title" label="文章標題" validate={required("*必須填寫文章標題")} multiline
                                           resettable/>
                                <FileInput source="videoFile" label={"影音檔上傳(檔案大小須小於30MB)"}
                                           placeholder={<p><FileUploadOutlinedIcon/>上傳影音檔案</p>}
                                           validate={required("*必須上傳影音檔案")}
                                           name="videoFile">
                                    <FileField source="src" title="title"/>
                                </FileInput>
                                <TextInput name="txtContent" multiline resettable rows={6} source="txtContent" label="影片說明"
                                           validate={required("*必須填寫影片說明")}/>
                                <AutocompleteArrayInput source="tags" label="標簽(最多10組)" optionValue="tagId" optionText="tagName" choices={data}  name="tags"/>
                                <div className={'border'}>
                                    <ImageInput source="coverImgFile" name="coverImgFile"
                                                label={
                                                    <span className={'text-lg text-red-400 m-2'}>*文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG、WEBP檔案大小限製:3MB)</span>
                                                }
                                                validate={required("*必須上傳封面圖")}
                                                placeholder={<div><FileUploadOutlinedIcon/>上傳檔案</div>}>
                                        <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: '100%', objectFit: 'contain' } }}  source="src" title="title"/>
                                    </ImageInput>
                                </div>
                            </SimpleForm>
                        )
                    }
                    {
                        contentTypeTabIdx === 4 && (
                            <SimpleForm  toolbar={<BlogCtrbItemToolbar textContent={textContent} category={categoryTabIdx} contentType={contentTypeTabIdx}/>} warnWhenUnsavedChanges>
                                <TextInput name="title" source="title" label="文章標題" validate={required("*必須填寫文章標題")} multiline
                                           resettable/>
                                <TextInput name="weblink" source="weblink" label="YouTube連結"
                                           validate={required("*必須填寫YouTube連結")}/>
                                <TextInput name="txtContent" multiline resettable rows={7} source="txtContent" label="影片說明"
                                           validate={required("*必須填寫影片說明")}/>
                                <AutocompleteArrayInput source="tags" label="標簽(最多10組)" optionValue="tagId" optionText="tagName" choices={data}  name="tags"/>
                                <div className={'border'}>
                                    <ImageInput source="coverImgFile" name="coverImgFile"
                                                label={
                                                    <span className={'text-lg text-red-400 m-2'}>*文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG、WEBP檔案大小限製:3MB)</span>
                                                }
                                                validate={required("*必須上傳封面圖")}
                                                placeholder={<div><FileUploadOutlinedIcon/>上傳檔案</div>}>
                                        <ImageField sx={{ '& .RaImageField-image': { width: '100%', height: '100%', objectFit: 'contain' } }}  source="src" title="title"/>
                                    </ImageInput>
                                </div>
                            </SimpleForm>
                        )
                    }
                </Create>
            </CardContent>
        </Card>
    )
}

export default BlogCtrbItemCreate;
