import {Button, Confirm, fetchUtils, Loading, useNotify, usePermissions} from "react-admin";
import * as React from "react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const ResetIdxConfirmButton = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const  notify = useNotify();
    const [isLoading, setIsLoading] = React.useState(false);
    const {permissions, error, isLoading: permissionsing} = usePermissions();

    const handleClose = async () => {
        setIsOpen(false);
    }

    const handleClickButton = () => {
        setIsOpen(true);
    }

    const handleConfirm = async () => {
        setIsLoading(true);
        fetchUtils.fetchJson('/api/blogitems/resetidx',{
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${localStorage.getItem('user')? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
            }),
        })
            .then(({ json }) => {
                setIsLoading(false);
                handleClose();
                notify('重置索引成功', { type:'success' });
            })
            .catch(error => {
                setIsLoading(false);
                handleClose();
                notify('重置索引失敗', { type: 'error' });
            });
    }

    if (error) return <p>Error</p>;
    if (permissionsing) return <Loading />;

    return (
        (permissions && permissions.includes('sys_resetidx_blogitems')) && (
            <>
                <Button onClick={handleClickButton} label={'重置索引'}>
                    <RestartAltIcon />
                </Button>
                <Confirm
                    isOpen={isOpen}
                    loading={isLoading}
                    title="重置所有部落格文章索引"
                    content="您確定要重置所有部落格文章的索引嗎？"
                    onConfirm={handleConfirm}
                    onClose={handleClose}
                />
            </>
        )
    )
}

export default ResetIdxConfirmButton;
