import {FieldProps} from "ra-ui-materialui/src/field/types";
import {Loading, useGetList, useRecordContext} from "react-admin";
import {Chip, Stack} from "@mui/material";
import React from "react";

const BlogRelatedTagsField =({source}: FieldProps) => {
    const record = useRecordContext();
    const { data, isPending, error } = useGetList(
        'blogtags',
        {
            pagination: { page: 1, perPage: 1000 }
        }
    );
    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const blogTags = data?.filter(item => record?.relatedTags.includes(item.tagId));
    return blogTags ? (
        blogTags.length <= 5 ? (
            <Stack direction="row" spacing={1}>
                {
                    blogTags.map((tag) => (<Chip color={"secondary"} key={tag.id} label={tag.tagName} />))
                }
            </Stack>
        ) : (
            <Stack direction="row" spacing={1}>
                {
                    blogTags.slice(0,5).map((tag) => (<Chip color={"secondary"} key={tag.id} label={tag.tagName} />))
                }
                <div className={'text-2xl font-black text-blue-500'}>...</div>
            </Stack>
        )
    ) : <div>無關聯標籤</div>;

}

export default BlogRelatedTagsField;
