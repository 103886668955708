import {
    Create, DateTimeInput, email, ListButton, PasswordInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar, useCreateContext,
} from "react-admin";
import * as React from "react";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Grid} from "@mui/material";


export const CreateToolbar = () => {
    const { isPending } = useCreateContext();

    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="保存" />
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={isPending} size={"medium"} label="返回" variant={"outlined"}  icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const AdminCreate = () => {
    const equalToPassword = (value: any, allValues: { password: any; }) => {
        if (value !== allValues.password) {
            return '兩次密碼不一致';
        }
    }

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Create redirect={"list"}>
                    <SimpleForm toolbar={<CreateToolbar/>} warnWhenUnsavedChanges>
                        <TextInput source="adminId" label="用戶ID" validate={required("*用戶Id必須填寫")} name="adminId" />
                        <TextInput source="email" label="Email" validate={[required("*用戶Email必須填寫"),email("*郵箱格式不正確")]} name="email"/>
                        <div className={'md:w-2/12 w-full'}>
                            <DateTimeInput source="expiredTime" parse={value => value ? new Date(value).getTime() : -1}  name="expiredTime" label="帳戶過期時間" />
                        </div>
                        <PasswordInput source="password" name="password" label="用戶密碼" validate={required("*密碼必須填寫")} />
                        <PasswordInput source="confirm_password" name="confirm_password" label="用戶密碼確認" validate={[required("*確認密碼必須填寫"),equalToPassword]} />
                        <TextInput name="remark" multiline resettable rows={7} source="remark" label="帳戶說明"/>
                    </SimpleForm>
                </Create>
            </CardContent>
        </Card>
    );
};

export default AdminCreate;
