import {FieldProps} from "ra-ui-materialui/src/field/types";
import {DateField, useRecordContext} from "react-admin";
import {Stack} from "@mui/material";
import React from "react";

const AdminStatusField =({source}: FieldProps) => {
    const record = useRecordContext();

    return record ? (
        <Stack direction={"row"} spacing={0}>
            {
                record[source] <= 0 ? (
                    <div>永不過期</div>
                    ) : (
                    <DateField source={source} showTime />
                )
            }
        </Stack>
    ) : null;

}

export default AdminStatusField;
