import React, {Fragment, useCallback, useState} from 'react';
import {
    BulkDeleteButton,
    Button, Confirm,
    CreateButton,
    DatagridConfigurable, DateField, DeleteWithConfirmButton,
    EditButton, email, FilterButton, FunctionField,
    List,
    Loading,
    SearchInput, SelectColumnsButton,
    TextField, TextInput,
    TopToolbar, useListContext, usePermissions,
} from 'react-admin';
import {Theme, useMediaQuery} from "@mui/material";
import MobileGrid from "../orders/MobileGrid";
import AdminStatusField from "../components/AdminStatusField";
import AdminRowActions from "./AdminRowActions";

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="新增用戶" />
        <FilterButton />
        <SelectColumnsButton/>
    </TopToolbar>
);

const tagFilters = [
    <SearchInput source="adminId" alwaysOn placeholder="用戶Id"  name="adminId" key="adminId" />,
    <TextInput name="email" source="email" placeholder="用戶EMail" key="email" label="用戶email" validate={email()} />,
];

const AdminList = () =>{
    return (
        <List
            sort={{ field: 'createTime', order: 'DESC' }}
            perPage={10}
            actions={<ListActions />}
            filters={tagFilters}>
            <AdminListDatagrid />
        </List>
    )
}

const AdminListDatagrid = () => {
    const { permissions, isPending: isLoading } = usePermissions();
    const {isPending, error} = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    if (isPending || isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Fragment>
            {isXSmall ? (
                <MobileGrid />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick={false}>
                    <TextField source="id" label="帳戶名"/>
                    <TextField source="email" label="用戶郵箱"/>
                    <FunctionField
                        label="用戶角色名稱"
                        render={record => `${record.authRoleName ? record.authRoleName : '無角色'}`}
                    />
                    <AdminStatusField source="expiredTime" label="帳戶過期時間" />
                    <DateField source="createTime" sortBy="createTime" sortByOrder="DESC" showTime label="創建時間" />
                    <DateField source="updateTime" sortBy="createTime" sortByOrder="DESC" showTime label="更新時間" />
                    {(permissions && (permissions.includes('sys_delete_admin') || permissions.includes('sys_edit_admin') || permissions.includes('sys_authorize_admin'))) && <AdminRowActions label="操作" source="actions"/>}
                </DatagridConfigurable>
            )
            }
        </Fragment>
    );
};

export default AdminList;
