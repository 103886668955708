import {
    AutocompleteArrayInput,
    Create, ListButton, Loading,
    required,
    SaveButton, SelectInput,
    SimpleForm,
    TextInput,
    Toolbar, useCreateContext, useGetList,
} from "react-admin";
import * as React from "react";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Grid} from "@mui/material";


export const CreateToolbar = () => {
    const { isPending } = useCreateContext();

    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="保存" />
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={isPending} size={"medium"} label="返回" variant={"outlined"}  icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const BlogTagsCreate = () => {
    const { data, isPending, error } = useGetList(
        'blogtags',
        {
            pagination: { page: 1, perPage: 1000 }
        }
    );
    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Create redirect={"list"}>
                    <SimpleForm toolbar={<CreateToolbar/>} warnWhenUnsavedChanges>
                        <TextInput source="tagId" label="標簽ID" validate={required()} name="tagId" />
                        <TextInput source="tagName" label="中文標籤名" validate={required()} name="tagName"/>
                        <SelectInput source="type" label="標簽類型" name="type"
                                     choices={[
                                         { id: 0, name: '一般標簽' },
                                         { id: 1000, name: '熱門標簽' },
                                     ]} validate={required()} />
                        <AutocompleteArrayInput source="relatedTags" label="關聯標簽(最多10組)" optionValue="tagId" optionText="tagName" choices={data}  name="relatedTags"/>
                    </SimpleForm>
                </Create>
            </CardContent>
        </Card>
    );
};

export default BlogTagsCreate;
