import { TranslationMessages } from 'react-admin';

export const zhTWMessages: TranslationMessages = {
    ra: {
        action: {
            add_filter: '新增篩選條件',
            add: '新增',
            back: '返回',
            bulk_actions: '已選擇1項 |||| 已選擇%{smart_count}項',
            cancel: '取消',
            clear_array_input: '清空清單',
            clear_input_value: '清除數值',
            clone: '複製',
            confirm: '確認',
            create: '建立',
            create_item: '建立 %{item}',
            delete: '刪除',
            edit: '編輯',
            export: '匯出',
            list: '列表',
            refresh: '重新整理',
            remove_filter: '移除此篩選',
            remove_all_filters: '移除所有篩選條件',
            remove: '移除',
            save: '儲存',
            search: '搜尋',
            select_all: '全選',
            select_all_button: '全選',
            select_row: '選取此列',
            show: '顯示',
            sort: '排序',
            undo: '復原',
            unselect: '取消選取',
            expand: '展開',
            close: '關閉',
            open_menu: '開啟選單',
            close_menu: '關閉選單',
            update: '更新',
            move_up: '上移',
            move_down: '下移',
            open: '開啟',
            toggle_theme: '切換亮/暗模式',
            select_columns: '欄位',
            update_application: '重新載入應用程式',
        },
        boolean: {
            true: '是',
            false: '否',
            null: 'null',
        },
        page: {
            create: '建立 %{name}',
            dashboard: '儀表板',
            edit: '編輯 %{name} %{recordRepresentation}',
            error: '發生錯誤',
            list: '%{name}',
            loading: '載入中',
            not_found: '找不到',
            show: '顯示 %{name} %{recordRepresentation}',
            empty: '尚無 %{name}。',
            invite: '要新增一個嗎？',
            access_denied: '拒絕存取',
            authentication_error: '認證錯誤',
        },
        input: {
            file: {
                upload_several:
                    '拖曳檔案以進行上傳，或點擊選擇檔案。',
                upload_single:
                    '拖曳檔案上傳，或點擊以選擇檔案。',
            },
            image: {
                upload_several:
                    '拖曳圖片以進行上傳，或點擊選擇圖片。',
                upload_single:
                    '拖曳圖片以進行上傳，或點擊選擇圖片。',
            },
            references: {
                all_missing: '找不到相關資料。',
                many_missing:
                    '至少有一個相關參考資料似乎已不可用。',
                single_missing:
                    '相關參考資料似乎已不可用。',
            },
            password: {
                toggle_visible: '隱藏密碼',
                toggle_hidden: '顯示密碼',
            },
        },
        message: {
            about: '關於',
            access_denied:
                '您沒有權限存取此頁面',
            are_you_sure: '您確定嗎？',
            authentication_error:
                '認證伺服器返回錯誤，無法驗證您的憑證。',
            auth_error:
                '驗證認證令牌時發生錯誤。',
            bulk_delete_content:
                '您確定要刪除這個 %{name} 嗎？ |||| 您確定要刪除這 %{smart_count} 個項目嗎？',
            bulk_delete_title:
                '刪除 %{name} |||| 刪除 %{smart_count} 個 %{name}',
            bulk_update_content:
                '您確定要更新這個 %{name} 嗎？ |||| 您確定要更新這 %{smart_count} 個項目嗎？',
            bulk_update_title:
                '更新 %{name} |||| 更新 %{smart_count} 個 %{name}',
            clear_array_input:
                '您確定要清空整個清單嗎？',
            delete_content:
                '您確定要刪除此項目嗎？',
            delete_title: '刪除 %{name} #%{id}',
            details: '詳細資料',
            error:
                '發生客戶端錯誤，無法完成您的請求。',
            invalid_form:
                '表單無效。請檢查是否有錯誤',
            loading: '請稍候',
            no: '否',
            not_found:
                '您可能輸入了錯誤的 URL，或點擊了無效的連結。',
            select_all_limit_reached:
                '元素過多，無法全選。僅選取了前 %{max} 個元素。',
            unsaved_changes:
                '部分變更尚未儲存。您確定要放棄它們嗎？',
            yes: '是',
            placeholder_data_warning:
                '網路問題：資料刷新失敗。',
        },
        navigation: {
            clear_filters: '清除篩選條件',
            no_filtered_results:
                '根據當前篩選條件，找不到任何 %{name}。',
            no_results: '找不到任何 %{name}。',
            no_more_results:
                '頁碼 %{page} 超出範圍。請嘗試前一頁。',
            page_out_of_boundaries:
                '頁碼 %{page} 超出範圍',
            page_out_from_end:
                '無法超過最後一頁',
            page_out_from_begin:
                '無法小於第 1 頁',
            page_range_info:
                '%{offsetBegin}-%{offsetEnd} / 共 %{total}',
            partial_page_range_info:
                '%{offsetBegin}-%{offsetEnd} / 超過 %{offsetEnd}',
            current_page: '第 %{page} 頁',
            page: '前往第 %{page} 頁',
            first: '前往第一頁',
            last: '前往最後一頁',
            next: '前往下一頁',
            previous: '前往上一頁',
            page_rows_per_page: '每頁筆數：',
            skip_nav: '跳至內容',
        },
        sort: {
            sort_by: '依 %{field_lower_first} %{order} 排序',
            ASC: '遞增',
            DESC: '遞減',
        },
        auth: {
            auth_check_error: '請先登入以繼續',
            user_menu: '個人資料',
            username: '使用者名稱',
            password: '密碼',
            email: '電子郵件',
            sign_in: '登入',
            sign_in_error: '認證失敗，請重試',
            logout: '登出',
        },
        notification: {
            updated: '項目已更新 |||| %{smart_count} 個項目已更新',
            created: '項目已建立',
            deleted: '項目已刪除 |||| %{smart_count} 個項目已刪除',
            bad_item: '不正確的項目',
            item_doesnt_exist: '項目不存在',
            http_error: '伺服器通訊錯誤',
            data_provider_error:
                'dataProvider 錯誤。請檢查主控台以獲取詳細資訊。',
            i18n_error:
                '無法載入指定語言的翻譯',
            canceled: '動作已取消',
            logged_out:
                '您的會話已結束，請重新連線。',
            not_authorized:
                '您無權存取此資源。',
            application_update_available:
                '有新版本可供更新。',
        },
        validation: {
            required: '必填',
            minLength: '至少需要 %{min} 個字元',
            maxLength: '最多 %{max} 個字元',
            minValue: '最小值為 %{min}',
            maxValue: '最大值為 %{max} 或更少',
            number: '必須是數字',
            email: '必須是有效的電子郵件',
            oneOf: '必須是其中之一：%{options}',
            regex: '必須符合特定格式（正規表達式）：%{pattern}',
            unique: '必須唯一',
        },
        saved_queries: {
            label: '已儲存的查詢',
            query_name: '查詢名稱',
            new_label: '儲存目前查詢...',
            new_dialog_title: '另存目前查詢為',
            remove_label: '移除已儲存的查詢',
            remove_label_with_name: '移除查詢 "%{name}"',
            remove_dialog_title: '移除已儲存的查詢？',
            remove_message:
                '您確定要從已儲存查詢列表中移除該項目嗎？',
            help: '過濾列表並儲存此查詢以供稍後使用',
        },
        configurable: {
            customize: '自訂',
            configureMode: '設定此頁面',
            inspector: {
                title: '檢查器',
                content: '將滑鼠移至應用程式 UI 元素以進行設定',
                reset: '重設設定',
                hideAll: '全部隱藏',
                showAll: '全部顯示',
            },
            Datagrid: {
                title: '資料表格',
                unlabeled: '未標示欄位 #%{column}',
            },
            SimpleForm: {
                title: '表單',
                unlabeled: '未標示輸入 #%{input}',
            },
            SimpleList: {
                title: '列表',
                primaryText: '主要文字',
                secondaryText: '次要文字',
                tertiaryText: '第三文字',
            },
        },
    },
};

export default zhTWMessages;
