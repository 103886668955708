import { AuthProvider } from 'react-admin';

const authProvider: AuthProvider = {
    async login({ username, password, reCAPTCHA })  {
        const request = new Request('/admin/login', {
            method: 'POST',
            body: JSON.stringify({ username, password, reCAPTCHA }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if(auth && auth.status === 200){
                    localStorage.setItem('user', JSON.stringify(auth.data));
                }
            })
            .catch((error) => {
                throw new Error(error);
            });
    },
    async logout() {
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    async checkError (error)  {
        const status = error.status;
        if (status === 401) {
            localStorage.removeItem('user');
            throw new Error('Session expired');
        }
    },
   async checkAuth () {
       const request = new Request('/admin/check', {
           method: 'GET',
           headers: new Headers({
               'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
           }),
       });
       return fetch(request)
           .then(response => {
               if (response.status === 401 || response.status === 403) {
                   localStorage.removeItem('user');
                   return Promise.reject(response.statusText);
               }
               return response.json();
           })
           .then(auth => {
               if(auth && auth.status !== 200){
                   return Promise.reject(auth.message);
               }
               return Promise.resolve();
           })
           .catch((error) => {
               return Promise.reject(error);
           });
    },
    async getPermissions()  {
        const user = localStorage.getItem('user');
        if(user && JSON.parse(user).permissions){
            return JSON.parse(user).permissions;
        }
    },
    async getIdentity () {
        const user = localStorage.getItem('user');
        if(user){
            const item = JSON.parse(user);
            return {
                ...item,
                id: item.adminId,
                fullName: item.adminId,
            }
        }
    },
    async canAccess({ action, resource }) {
        const user = localStorage.getItem('user');
        if(user && JSON.parse(user).permissions){
            const authorizedResources = JSON.parse(user).permissions;
           if(authorizedResources && authorizedResources.length > 0){
               return getPermissions(action, resource ,authorizedResources);
           }
        }
        return false;
    },
};

const getPermissions = (action: string, resource: string, authorizedResources: string[]) => {
    if(resource.indexOf('mcampaignlogs') > -1){
        return authorizedResources.includes(`sys_${action}_mcampaignlogs`);
    }
    switch (resource){
        case 'admin':
            return authorizedResources.includes(`sys_${action}_admin`);
        case 'role':
            return authorizedResources.includes(`sys_${action}_role`);
        case 'blogtags':
            return authorizedResources.includes(`sys_${action}_blogtags`);
        case 'blogitems':
            return authorizedResources.includes(`sys_${action}_blogitems`);
        case 'blogctrbitems':
            return authorizedResources.includes(`sys_${action}_blogctrbitems`);
        case 'mcampaigns':
            return authorizedResources.includes(`sys_${action}_mcampaigns`);
        default:
            return false;
    }
}

export default authProvider;
