import * as React from 'react';
import {useEffect} from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper, {PaperProps} from '@mui/material/Paper';
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import {Loading, useGetList} from "react-admin";
import Draggable from 'react-draggable';

function not(a: readonly TransferListItemType[], b: readonly TransferListItemType[]) {
    return a.filter((value) => !b.includes(value));
}

function intersection(a: readonly TransferListItemType[], b: readonly TransferListItemType[]) {
    return a.filter((value) => b.includes(value));
}

export type TransferListItemType = {
    id: string;
    value: string;
}

const  PaperComponent = (props: PaperProps) => {
    const nodeRef = React.useRef<HTMLDivElement>(null);
    return (
        <Draggable
            nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function TransferList({itemId, title, isOpen, category, reference, onClose, onSave}:
    Readonly<{ itemId:string,title: string, isOpen: boolean,category: number,reference: string[],
        onClose:()=>void,
        onSave:(ids: string[])=>void }>) {
    const { data, isPending, error } = useGetList(
        'blogitems',
        {
            pagination: { page: 1, perPage: 100 },
            filter: {category: category, onList: [1,100]}
        }
    );
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
    const [checked, setChecked] = React.useState<readonly TransferListItemType[]>([]);
    const [right, setRight] = React.useState<readonly TransferListItemType[]>([]);
    const [left, setLeft] = React.useState<readonly TransferListItemType[]>([]);

    useEffect(() => {
        if(data){
            const newData = data.filter(item=>item.id !== itemId).map((item=>{
                return {id: item.itemId, value: item.title}
            }));
            if(reference){
                const newLeftDate = newData.filter(item => reference.includes(item.id));
                if(newLeftDate){
                    setLeft(newLeftDate);
                    setRight(not(newData,newLeftDate));
                }
            } else {
                setLeft([]);
                setRight(newData);
            }
        }
    }, [category,itemId,data,isOpen]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: TransferListItemType) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    useEffect(() => {
        const newReference = left.map(item => item.id);
        const oldReference = reference || [];
        // 檢查長度是否相同
        if (newReference.length !== oldReference.length) {
            setIsDisabled(false);
        } else {
            const arraysAreEqual = newReference.sort().every((value, index) => value === oldReference.sort()[index]);
            setIsDisabled(arraysAreEqual);
        }
    }, [left,reference]);

    const handlerSave = () => {
        setIsDisabled(true);
        onSave(left.map(item => item.id));
    }

    const customList = (isChecked: boolean,items: readonly TransferListItemType[]) => (
        <Paper sx={{ width: 300, height: 430, overflow: 'auto' }}>
            <List dense component="div" role="list">
                <ListItemButton
                    key="left"
                    disabled={leftChecked.length === 0}
                    onClick={handleCheckedLeft}
                >
                    <ListItemText primary={isChecked ? '已選擇' : '未選擇'} />
                </ListItemButton>
                <Divider />
                {items.map((item) => {
                    const labelId = `transfer-list-item-${item.id}-label`;
                    return (
                        <ListItemButton
                            key={item.id}
                            role="listitem"
                            onClick={handleToggle(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.includes(item)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item.value} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    if (isPending) { return <Loading />; }
    if (error) { return null; }

    return (
        isOpen && (
            <Dialog
                maxWidth={'md'}
                open={isOpen}
                onClose={onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item>{customList(true,left)}</Grid>
                        <Grid item style={{alignContent: 'center'}}>
                            <Grid container direction="column" >
                                <Button
                                    sx={{my: 0.5}}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{my: 0.5}}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{my: 0.5}}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{my: 0.5}}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList(false,right)}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onClose}>
                        取消
                    </Button>
                    <Button disabled={isDisabled} onClick={handlerSave}>確定</Button>
                </DialogActions>
            </Dialog>
        )
    );
}
