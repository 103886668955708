import {
    ChipField, CreateButton,
    DatagridConfigurable,
    DateField, DateInput,
    ImageField,
    List, Loading, SearchInput, SelectColumnsButton,
    TextField, TopToolbar, useListContext, usePermissions
} from "react-admin";
import * as React from "react";
import {Theme, useMediaQuery} from "@mui/material";
import {Fragment} from "react";
import MobileGrid from "../orders/MobileGrid";
import MCampaignRowActions from "./MCampaignRowActions";

const ListActions = () => (
    <TopToolbar>
        <CreateButton label={'新增行銷活動'} />
        {/*<FilterButton/>*/}
        <SelectColumnsButton />
    </TopToolbar>
);

const PostList = () => {
    return (
        <List
            sort={{ field: 'createTime', order: 'DESC' }}
            perPage={10}
            filters={orderFilters}
            actions={<ListActions/>}
        >
            <TabbedDatagrid />
        </List>
    )
}

const orderFilters = [
    <SearchInput name="name" source="name" alwaysOn placeholder="活動名稱" key="name" />,
    <DateInput source="createTime" parse={d => new Date(d).getTime()} name="createTime" label="創建時間" key="createTime" />,
    <DateInput source="startDate" parse={d => new Date(d).getTime()} name="createTime" label="活動開始時間" key="startDate" />,
    <DateInput source="endDate" parse={d => new Date(d).getTime()} name="lastUpdTime" label="活動結束時間" key="endDate" />,
];

const TabbedDatagrid = () => {
    const {permissions, isPending: isLoading} = usePermissions();
    const listContext = useListContext();
    const {isPending, error } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    if (isPending || isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Fragment>
            {isXSmall ? (
                <MobileGrid />
            ) : (
                <DatagridConfigurable rowClick={false}
                                      bulkActionButtons={false}
                                      omit={['startDate', 'endDate', 'coverImage']}
                >
                    <TextField source="name" label="活動名稱"/>
                    <DateField source="createTime" showTime label="投稿時間"/>
                    <ChipField source="logCount" color={"secondary"} label="活動留言數量"/>
                    <TextField source="startDate"  label="活動開始時間"/>
                    <TextField source="endDate" label="活動結束時間"/>
                    <ImageField source="coverImage" label="活動封面圖" />
                    {(permissions && (permissions.includes('sys_edit_mcampaigns') || permissions.includes('sys_list_mcampaignlogs'))) && <MCampaignRowActions source="actions" label="操作" />}
                </DatagridConfigurable>
            )}
        </Fragment>
    );
};

export default PostList;
